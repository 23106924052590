export default function CozIconSVG() {
  return (
    <svg className="mr-2 h-6 w-6 rotate-45" id="COZicon" viewBox="0 0 16 16">
      <path
        className="logo"
        d="M8,3.33V0C3.58,0,0,3.58,0,8v7h7v-7h-3.67c0-2.58,2.09-4.67,4.67-4.67Z"
      />
      <path
        className="logo"
        d="M15,2v6c0,3.52-2.61,6.44-6,6.93v-1.15c2.84-.51,5-2.99,5-5.98v-.8h-4V2h5M16,1h-7v7h3.67c0,2.58-2.09,4.67-4.67,4.67v3.33c4.42,0,8-3.58,8-8V1h0Z"
      />
    </svg>
  );
}
